import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/static-page.js";
import BlogList from "../components/blog-list/blog-list";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTALink = makeShortcode("CTALink");
const LittleH3 = makeShortcode("LittleH3");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Hi, I’m Conor—a Minnesota-based web developer. I’m a sucker for hand-brewed coffee, good public transit, and
typographic nerdery.`}</p>
    <p>{`This site is my home on the internet, where I occasionally share thoughts on the web, design, marketing, and side projects I’m excited about. It’s heavily inspired by personal blogs of the good old days and the current IndieWeb movement.`}</p>
    <CTALink to="/about" mdxType="CTALink">The longer version</CTALink>
    <LittleH3 mdxType="LittleH3">Latest</LittleH3>
    <BlogList mdxType="BlogList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      